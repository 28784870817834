.create-pool {
  width: 100%;
  max-width: 1280px;
  background-color: white;
  margin-top: 32px;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.content {
  .form {
    border: none;
    box-shadow: none;
    width: 100%;
    max-width: 1072px;
    @media (max-width: 767px) {
      width: calc(100vw - 40px);
    }
  }


  .form-header {
    display: block;
    font-family: Optima;
    padding: 0 0 16px 0;
    border-bottom: 2px solid black;
    @media (max-width: 767px) {
      border-bottom: none;
    }
    .sub-title {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-transform: capitalize;
    }

    .title {
      font-family: Optima;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .form-content {
    width: 100%;
    max-width: 100%;
    margin: 0;
    @media (max-width: 767px) {
      padding: 0;
    }

    .form-item {
      border-top: 2px solid black;
      padding: 52px 0 0 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .form-item-label {
      font-family: Optima;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 32px;
    }

    .form-item-content {
      width: 560px;
      @media (max-width: 767px) {
        width: 100%;
      }
      .field {
        font-family: 'Helvetica Neue';
        font-style: normal;
        margin-bottom: 36px;

        dt, .label {
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 13px;
        } 

        dd {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }

        dd input {
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-width: 0 0 1px 0;
          border-radius: 0;
          padding: 0 0 5px 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          height: auto;
        }
        dd.image {
          height: 320px;
          border: 1px solid rgba(0, 0, 0, 0.3);
        }

        .to-label {
          position: relative;
          .to-icon {
            width: 18px;
            height: 18px;
            position: absolute;
            bottom: 8px;
            left: 0;
          }
          .to-input {
            padding-left: 25px;
            @media (max-width: 767px) {
              width: 100%;
            }
          }
        }

        .balance-max {
          width: 33px;
          height: 18px;
          border: 1px solid black;
          display: flex;
          align-items: center;
          justify-content: center;
          color: black;
          border-radius: 0;
          background-color: white;
          font-size: 12px;
          right: 0;
          top: 0;
        }

        .field-row {
          @media (max-width: 767px) {
            width: 100%;
          }
          li {
            width: 260px;
            @media (max-width: 767px) {
              width: 50%;
            }
          }
          input {
            margin: 13px 0 0 0;
          }
        }

        .field-row.type {
          width: 100%;
        }
        
        .eye {
          right: 10px;
        }

        label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .tip-title {
        color: #000;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        text-transform: capitalize;
      }
    }
  }
  
}