@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.waiting {
  margin-bottom: 34px;
  animation: spin 1s linear infinite;
}

.modal-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  opacity: 0.3;
  border-radius: 6px;
  flex-direction: column;
  span{
    font-size: 24px;
    color: #FFFFFF;
  }
}

.count-down {
  border: 1px solid black;
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 0;
  font-size: 16px;
  line-height: 19px;
  color: black;
  text-align: center  ;
  img {
    margin-bottom: 34px;
  }
  .title{
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    color: black;
    margin-bottom: 12px;
  }
  p:nth-of-type(1) {
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    color: black;
    margin-bottom: 12px;
  }
  p:nth-of-type(2) {
    margin-bottom: 12px;
  }
  .link {
    text-decoration: underline;
  }
  .button {
    bottom: 50px;
    margin-top: 20px;
  }
}

.button-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  .button {
    position: static;
  }
}


.denied-content {
  .button {
    margin-top: 170px;
  }
}

.wait-content {
  p:nth-of-type(3) {
    opacity: 0.5;
    margin-bottom: 110px;
  }
}

.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
  img {
    margin-bottom: 20px;
  }
  p {
    text-align: center;
    width: 300px;
    font-size: 20px;
    line-height: 24px;
  }
}


@media only screen and (max-width: 480px) {
  .content-box{
    p:nth-of-type(1) {
      font-size: 20px;
      line-height: 29px;
      font-weight: bold;
      color: black;
      margin-bottom: 12px;
    }
    .button{
      width: 90%!important;
    }
  }
}
