@font-face {
  font-family: Lato;
  src: url('../font/Lato-Regular.ttf')
}

@font-face {
  font-family: RobotoMono;
  src: url('../font/RobotoMono-Regular.ttf')
}


$color-black-light: rgba(0, 0, 0, 0.6);


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

.status {
  width: 68px;
  height: 28px;
}

.live {
  color: #36C98E;
}

.passed {
  color: #077BE5;
}

.fail {
  color: #EB2F51;
}


%flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-center {
  @extend %flex;
  justify-content: center;
}

html {
  font-size: 100%;
}

html,
body,
#root,
.container,
.desktop {
  height: 100%;
  perspective: none;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100vw;
  }
}

body{
  @media (max-width: 767px) {
    padding-bottom: 0!important;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.error {
  color: red;
}

.staking-info{
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;

  .item{
    width: 180px;
    height: 80px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #000;
    justify-content: center;
    align-items: center;
    background: #F8F8F9;

    span:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    span:nth-child(2) {
      font-size: 18px;
    }
  }
}

.weight-grey {
  color: #000;
  background: #F8F8F9;
  border-radius: 6px;
  font-weight: normal;
  margin: auto;
  padding: 0 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .item {
    flex: 1;
    height: 50px;
    padding-left: 20px;
    flex-direction: column;

    span:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    span:nth-child(2) {
      font-size: 18px;
    }
  }

  span {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
}


a {
  text-decoration: none;
  color: inherit;
  height: 100%;
  @extend %flex-center;
}

input::placeholder {
  color: $color-black-light;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noData {
  @extend %flex-center;
  height: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  height: 400px;
}

.select-container {
  width: 180px;
  height: 40px;
}

.nav-container {
  display: none;

  .content {
    width: 250px;
  }
}

.only-icon-container {
  width: 40px;
}

.select {
  width: 180px;
  height: 40px;
  font-size: 16px;
  color: black;
  text-align: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../image/down.png") no-repeat scroll right 12px center transparent;
  background-size: 14px auto;
  align-items: center;
  cursor: pointer;
  position: absolute;
  flex-direction: column;
  z-index: 1;
}

.select.only-icon {
  z-index: 2;
}

.select.hover {
  z-index: 2;
}



.select.only-icon {
  z-index: 2;
}


.select-default{
  width: 84%;
  height: 40px;
  line-height: 40px;
  z-index: 1;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  padding-left: 16px;

}


.icon {
  .select {
    width: auto;

    span {
      display: none;
    }

    .options {
      display: flex;
      right: 0;
    }
  }
}


.select:hover {
  background-color: black;
  color: white;

  .options {
    display: flex;
    z-index: 2;
  }
}

.options {
  display: none;
  width: 180px;
  color: black;
  background: #fff;
  line-height: 30px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  align-items: center;
  height: fit-content;
  flex-direction: column;
  padding-bottom: 10px;
  overflow: hidden;
}


.options-selected {
  display: flex;
}

.options:hover {
  .options {
    display: flex;
  }
}



.option {
  line-height: 30px;
  height: 30px;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  margin-top: 10px;
}

.option:hover {
  background-color: #EEEFF3;

  .options {
    display: flex;
  }
}

.column {
  @extend %flex;
  flex-direction: column;
}

.row {
  @extend %flex;
  justify-content: flex-start;
}

.tip {
  @extend %flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;

  img {
    margin: 0 2px;
  }
}

.tip:hover {
  .tip-message {
    display: block;
  }
}

.gear {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: url('../image/gear.svg') 0 0 no-repeat;
}

.gear:hover {
  background-image: url('../image/gear-hover.svg');
}

.tip-message {
  width: max-content;
  padding: 0 10px;
  font-size: 12px;
  font-weight: normal;
  background-color: white;
  position: absolute;
  top: -28px;
  left: 0;
  display: none;
  z-index: 3;
  line-height: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  max-width: 400px;
}

body {
  background-color: white;
  color: black;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
}

.link {
  font-size: 12px !important;
  text-decoration: underline;
}

.button {
  height: 40px;
  @extend %flex-center;
  margin-left: 16px;
  border: 1px solid black;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  padding: 0 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.button.disabled {
  color: rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 0, 0, 0.3);
}

.button.disabled:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
}

.button.current,
.button:hover {
  color: white;
  background-color: black;
}

.dark-button {
  @extend %flex-center;
  width: 100%;
  height: 40px;
  color: white;
  background-color: black;
  border-radius: 4px;
  cursor: pointer;
}

.dark-button.search-button {
  width: 160px;
  border-radius: 0 6px 6px 0;
}

.link {
  font-size: 20px;
  line-height: 24px;
  color: $color-black-light;
  cursor: pointer;
}

.link.current {
  font-weight: bold;
  ;
  color: black;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.container.create {
  height: 1130px;
}

.mobile {
  display: none;
  flex-direction: column;
}

.more-info {
  display: none;
  margin-left: 10px;
}

.head {
  @extend %flex;
  width: 100%;
  height: 90px;
  border-bottom: 1px solid black;
  position: relative;

  .row a {
    margin-left: -100px;
  }

  .button {
    width: 200px;
  }

  .action {
    @extend %flex;
  }

  .wallet-info {
    @extend %flex;

    .wallet-info-item {
      height: 40px;
      border: 1px solid black;
      @extend %flex;
      border-radius: 6px;
      padding-left: 20px;
      background-color: #F5F7FC;
    }

    .network {
      background-color: rgba(243, 132, 30, 0.05);
      color: rgb(243, 132, 30);
      font-weight: 500;
      margin-right: 0.5em;
      border-radius: 5px;
      padding: 5px 10px;
    }

    .copyArea {
      position: relative;
      border-left: 1px solid black;
      border-radius: 6px;
      height: 100%;
      @extend %flex;
      background-color: white;
      padding: 0 20px;
      margin-left: 16px;

      .copied {
        position: absolute;
        top: 26px;
        right: 10px;
        font-size: 12px;
        padding: 4px 10px;
        color: white;
        background-color: black;
        border-radius: 4px;
      }
    }

    .account {
      @extend %flex;
      cursor: pointer;

      img {
        margin-left: 0.5em;
      }
    }
  }

  .menu {
    width: 20px;
    height: 14px;
    background: url('../image/menu.svg') 0 0 no-repeat;
    cursor: pointer;
    display: none;
  }
}

.stake-button-layout {
  right: 34px;

  .stake-button {
    width: 100%;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 12px;
    font-size: 16px;
    text-align: center;
  }

  .button-unable {
    background: #EEEFF3;
    pointer-events: none;
    cursor: not-allowed;
  }

  .active {
    background: #000000;
    border: 1px solid #000000;
    color: #fff;
  }

  .stake-button:hover {
    background: #000000;
    border: 1px solid #000000;
    color: #fff;
  }
}

.status {
  @extend %flex-center;
  height: 28px;
  font-size: 14px;
  line-height: 130%;
  border-radius: 4px;
  margin-right: 26px;
}

.status.Live {
  color: #36C98E;
  //background-color: rgba(54, 201, 142, 0.1);
}

.status.Closed {
  color: rgba(0, 0, 0, 0.5);
  //background-color: rgba(255, 47, 81, 0.1);
}

.status.Filled {
  color: rgba(7, 123, 230, 1);
  //background-color: rgba(7, 123, 230, 0.1);
}

.status.Passed {
  color: rgba(7, 123, 230, 1);
  background-color: rgba(7, 123, 230, 0.1);
}

status.Fail {
  color: #EB2F51;
  background-color: rgba(255, 47, 81, 0.1);
}

.status.Finish {
  color: rgba(7, 123, 230, 1);
  background-color: rgba(7, 123, 230, 0.1);
}

.status.Partial {
  color: rgba(255, 136, 0, 1);
  background-color: rgba(255, 136, 0, 0.1);
}

.status.Unfilled {
  color: rgba(235, 47, 81, 1);
  background-color: rgba(235, 47, 81, 0.1);
}



.progress.Live {
  width: 100%;
  background-color: rgba(45, 171, 80, 0.1);
}

.progress.Pending {
  width: 100%;
  background-color: rgba(211, 211, 218, 0.3);
}

.progress-value.Pending {
  background-color: rgba(211, 211, 218, 1);
}

.progress.Successfully {
  background-color: #728AE0;
}

.progress.Unsuccessfully {
  background-color: rgba(0,0,0,0.1);
}

.progress-value.Unsuccessfully {
  background-color: rgba(0,0,0,0.4);
}

.progress-value.Live {
  background-color: rgba(45, 171, 80, 1);
}


.progress {
  width: 100%;
  background-color: rgba(211, 211, 218, 0.3);
}

.progress-value.Closed {
  background-color: rgba(211, 211, 218, 1)
}


.progress.Filled {
  width: 100%;
  background-color: rgba(7, 123, 230, 0.1);
}

.progress-value.Filled {
  background-color: rgba(7, 123, 230, 1)
}


.nft-grey{
  height: 86px;
  background: #F8F8F9;
  border-radius: 6px;
  padding: 33px;
  display: flex;
  flex-direction: row;

  .item{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.notice {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin-top: -30px;
  margin-bottom: 30px;

  img {
    margin: 0 4px;
  }
}

.side-nav {
  display: flex;
  width: 100%;
  height: 100%;
}

.nft-item{
  width: 100%;
  display: flex;

  .cover-box{
    position: relative;
    width: 50%;
  }

  .cover-mask{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    font-size: 16px;
    line-height: 124%;
    text-align: center;
    padding: 0 24px;
    text-decoration-line: underline;
    color: #FFFFFF;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    cursor: pointer;
    border-radius: 4px;
  }

  .nft-cover{
    width: 100%;
    cursor: pointer;
  }






  .nft-content{
    flex: 1;
  }

  .define{
    padding-left: 36px;
  }
}

.grid-pool-item{
  width: 250px;
  height: 360px;
  left: 180px;
  top: 295px;
  opacity: 0.8;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  cursor: pointer;

  &:nth-child(4n) {
    margin-right: 0;
  }

  .pool-num{
    font-size: 12px;
    line-height: 24px;
    color: #000000;
    margin-top: 10px;
  }

  .header{
    display: flex;
    height: 85px;
    border-bottom: 1px #000 solid;
    flex-direction: column;

    .name{
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin: 14px  16px 4px;
    }

    .address {
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      opacity: 0.5;
      margin: 0px 16px 4px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;

    }
  }

  .desc{
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    padding: 16px 24px;
  }

  .name{
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 14px  16px 4px;
  }

  .address {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.5;
    margin: 0px 16px 4px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;

  }

  .cover{
    width: 250px;
    height: 250px;
    border-radius: 6px;
    cursor: pointer;
  }
}

.balance-max{
  position: absolute;
  right: 20px;
  font-size: 14px;
  background-color: #EEEFF3;
  padding: 6px 20px;
  margin: auto;
  top: 5px;
  border-radius: 6px;
  cursor: pointer;
}

.content {
  padding-top: 50px;
  position: relative;

  .return {
    width: 100%;
    white-space: pre;
    display: flex;
    margin: -12px 0 12px 20px;

    a {
      @extend %flex;
    }
  }

  .form {
    position: relative;
    width: 100%;
    max-width: 600px;
    border: 1px solid black;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.12);
  }

  .grid-box{
    border: none;
    box-shadow: none;
    height: fit-content!important;
  }

  .home{
    max-width: 1080px;
    height: 540px;
    overflow: hidden;
    padding-bottom: 20px;
    .form-head{
      width: 1080px;
      margin: auto;
      padding: 34px;
    }

    .form-content {
      width: 100%;
      max-width: 100%;
      padding: 0 34px;

      .search {
        max-width: 100%;
      }

      .pools {
        .pool-content {
          width: 100%;
        }
      }
    }
  }

  .form-head {
    @extend %flex;
    padding: 0 34px;
    height: 70px;
    border-bottom: 1px solid black;
    font-size: 20px;
    line-height: 24px;
    position: relative;
  }

  .form-head.nav {
    justify-content: space-around;
  }

  .form-content {
    max-width: 532px;
    margin: 36px auto 18px auto;

    .join-detail-container {
      width: 100%;
      background-color: #F8F8F9;
      border-radius: 6px;
      display: flex;
      padding: 14px 30px;
      font-size: 14px;
      font-weight: bold;

      .item {
        flex: 1;
        height: 50px;
        padding-left: 20px;
        flex-direction: column;

        span:nth-child(2) {
          margin-top: 10px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }

  .search {
    @extend %flex;
    border: 1px solid black;
    border-radius: 6px;
    max-width: 532px;
    height: 40px;
    margin-bottom: 36px;

    input {
      border: none;
      padding-left: 26px;
      font-size: 14px;
      line-height: 17px;
    }

    button {
      @extend %flex-center;
      width: 108px;
      height: 100%;
      color: white;
      background-color: black;
    }
  }

  .pool-head {
    display: flex;
    font-size: 0.625rem;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.44);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding-bottom: 10px;

    li {
      display: flex;

      span {
        font-size: 10px;
        white-space: pre;
        text-size-adjust: none;
      }
    }
  }

  .pool-content {
    height: 340px;
    overflow: auto;
    width: 556px;
  }

  .pool-head,
  .pool-item {
    .mobile-pool-status {
      display: none;
    }

    li:nth-child(1) {
      display: none;
    }

    li:nth-child(2) {
      width: 60px;
    }

    li:nth-child(3) {
      width: 120px;
    }

    li:nth-child(4) {
      width: 160px;
    }

    li:nth-child(5) {
      width: 108px;
    }

    li:nth-child(6) {
      width: 220px;
      padding-right: 20px;
    }

    li:nth-child(7) {
      width: 108px;
    }

    li:nth-child(8) {
      width: 108px;
    }
  }


  .pool-item {
    @extend %flex;
    justify-content: flex-start;
    width: 532px;
    height: 58px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    li {
      white-space: pre;
    }

    li.pool-id {
      width: 60px;
    }

    li.pool-auth {
      .tip {
        width: 120px;
        margin-right: 20px;
      }

      .tip span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    li.pool-contract {
      .tip {
        width: 180px;
        margin-right: 20px;
      }

      .tip span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    li.pool-pair {
      font-family: RobotoMono;

      em {
        font-weight: bold;
      }
    }


    .status {
      @extend %flex-center;
      height: 28px;
      font-size: 14px;
      line-height: 130%;
      border-radius: 4px;
      margin-right: 26px;
    }

    .status.Live {
      color: #36C98E;
      background-color: rgba(54, 201, 142, 0.1);
    }

    .status.Closed {
      color: #EB2F51;
      background-color: rgba(255, 47, 81, 0.1);
    }

    .status.Filled {
      color: rgba(7, 123, 230, 1);
      background-color: rgba(7, 123, 230, 0.1);
    }

    .button {
      width: 74px;
      height: 30px;
      font-size: 14px;
      line-height: 17px;
      margin-left: 0;
    }
  }

  .pool-item-home {
    width: 100%;
  }

  .pool-status {
    @extend %flex-center;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid black;
    padding-left: 35px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    height: 100px;

    img {
      width: 32px;
      margin-right: 20px;
    }
  }
}

 .social_trust_list {
     width: 100%;
     display: flex;
     flex-direction: column;

     li {
       width: 100%;
       border-bottom: 1px solid rgba(02,02,02,.16);
       padding: 20px 20px 20px 35px;
       display: flex;
       align-items: center;

       &:hover {
         background-color: rgb(249, 249, 250);
         // cursor: pointer;
       }

       .left {
         width: 370px;

         div {
           display: flex;

           h1 {
             font-size: 18px;
             line-height: 22.6px;
           }

           span {
             display: block;
             height: 24px;
             line-height: 24px;
             border-radius: 4px;
             text-align: center;
             margin-left: 1em;
             padding: 0 .8em;

             &.Live {
               color: #36C98E;
               background-color: rgb(235, 250, 244);
             }
             &.Closed{
               color: #EB2F51;
               background-color: rgb(253, 234, 238);
             }
             &.Pending{
               color: #077BE5;
               background-color: rgb(230, 242, 252);
             }
           }

         }

         p {
           font-size: 14px;
           line-height: 17px;
           margin-top: 6px;
         }

         h5{
           font-size: 16px;
           margin-top: 10px;
           span{
             font-size: 14px;
           }
         }
       }

       .right {
         flex: 1;
         display: flex;
         justify-items: end;

         &>button {
           width: 100px;
           height: 30px;
           border: 1px solid #000;
           box-sizing: border-box;
           border-radius: 6px;
           margin-left: 12px;

           &:nth-child(1) {
             margin-left: auto;
           }
         }
       }
     }


   }




.content.create-pool {

  .define {
    margin-bottom: 26px;

    dt {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      margin-bottom: 6px;

      weight: {
        font-weight: normal;
      }
    }

    dd {
      font-size: 16px;
      line-height: 19px;
      min-height: 20px;
      color: #F8F8F9;
      color: rgba(0, 0, 0, 0.5);
      word-break: break-word;
    }
  }

  .not-found-content {
    height: fit-content;
  }

  .form {
    min-height: 560px;
  }

  .field {
    flex: 1;
    margin-bottom: 30px;

    .eye {
      position: absolute;
      right: 24px;
      font-size: 14px;
      margin: auto;
      border-radius: 6px;
      cursor: pointer;
      width: 24px;
      top: 0;
      bottom: 0;
    }

    .amount-input {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
    }

    dt {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      margin-bottom: 10px;

      .icon {
        margin-left: 10px;
      }
    }

    dd {
      display: flex;
      align-items: center;

      input {
        border: 1px solid rgba(0, 0, 0, 0.8);
        border-radius: 6px;
        width: 100%;
        height: 40px;
        padding-left: 14px;
        font-size: 16px;
      }

      input.fix {
        width: 100%;
        margin-right: 0.5em;

      }

      input::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      select {
        width: 140px;
        height: 40px;
        margin-left: 14px;
        padding: 0 10px;
        border-radius: 6px;
      }
    }

    dd.field-time {
      @extend %flex;

      input:nth-child(2n) {
        margin: 0 20px;
      }
    }
  }

  .field-row {
    @extend %flex;
    white-space: pre;

    li {
      @extend %flex;

      input {
        margin-left: 14px;
        width: 200px;
      }
    }

    li:first-child {
      margin-right: 19px;

      input {
        width: 204px;
      }
    }
  }

  .field-row.type {
    justify-content: flex-start;

    li {
      width: auto;
      margin-right: 50px;
    }

    input {
      margin: 0 0.5em;
    }

    input:checked+label {
      background: #000;
    }

  }

  .bid {
    width: 400px;
  }
}

.content.stake {
  .stake-title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    line-height: 24px;
  }



  input {
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    width: 100%;
    height: 40px;
    padding-left: 14px;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .fee {
    font-size: 24px;
    font-weight: bold;
    width: 58%;
    line-height: 29px;
    margin: auto;
    text-align: center;
  }

  .unstake {
    width: 54%;
  }

  .fee-tip {
    font-size: 16px;
    width: 72%;
    line-height: 26px;
    margin: auto;
    text-align: center;
    margin-top: 10px;
  }

  .balance {
    float: right;
  }

  .more {
    margin-top: 0;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .weight-grey {
    color: #000;
    background: #F8F8F9;
    border-radius: 6px;
    font-weight: normal;
    margin: auto;
    padding: 0 20px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
      flex: 1;
      height: 50px;
      padding-left: 20px;
      flex-direction: column;

      span:nth-child(1) {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      span:nth-child(2) {
        font-size: 18px;
      }
    }

    span {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }


  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .button-layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

}


.password {
  margin-bottom: 26px;
  margin-top: 30px;

  dt {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    margin-bottom: 6px;

    weight: {
      font-weight: normal;
    }
  }

  dd {
    font-size: 16px;
    line-height: 19px;
    min-height: 20px;

    .eye {
      position: absolute;
      right: 12px;
      font-size: 14px;
      margin: auto;
      border-radius: 6px;
      cursor: pointer;
      width: 24px;
      top: 0;
      bottom: 0;
    }
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    width: 100%;
    height: 40px;
    padding-left: 14px;
    font-size: 16px;
  }
}

.content.join {
  .join-title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    line-height: 24px;

    li:last-child {
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .define {
    margin-bottom: 26px;

    dt {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      margin-bottom: 6px;

      weight: {
        font-weight: normal;
      }
    }

    dd {
      font-size: 16px;
      line-height: 19px;
      min-height: 20px;
      color: #F8F8F9;
      color: rgba(0, 0, 0, 0.5);

    }
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    width: 100%;
    height: 40px;
    padding-left: 14px;
    font-size: 16px;
  }

  .weight-grey {
    color: rgba(0, 0, 0, 0.5);
    font-weight: normal;
  }

  .ratio {
    @extend %flex;
    justify-content: flex-end;
    width: 280px;
    height: 18px;
    border: 1px solid black;
    border-radius: 6px;
    padding-right: 12px;
    font-size: 12px;
    position: relative;
    margin-right: 10px;
  }

  .ratio .back {
    content: ' ';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(54, 201, 142, 0.9);
    border-radius: 5px;
  }

  .ratio .text {
    position: relative;
    z-index: 2;
  }

  .define-row {
    @extend %flex;
    justify-content: flex-start;
  }

  .define-line {
    @extend %flex;
    justify-content: flex-start;

    span {
      width: 1em;
      margin: 0 0.5em;
    }
  }
}

.content.connect {

  .wallet-item {
    @extend %flex;
    height: 60px;
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding: 0 30px;
    cursor: pointer;
    background-position: 100% center;
    background-repeat: no-repeat;
    background-origin: content-box;
    margin-bottom: 20px;

    .init {
      width: 100%;
      height: 100%;
      @extend %flex
    }

    .success {
      position: relative;
      @extend %flex;
      width: 100%;

      .button {
        width: 100px;
        height: 30px;
        font-size: 12px;
        @extend %flex-center;
        display: none;
      }
    }

    .success:before {
      content: ' ';
      position: absolute;
      left: -16px;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: green;
      border-radius: 10px;
    }
  }

  .initMetaMask {
    width: 100%;
    height: 100%;
    @extend %flex
  }

  .wallet-item.failed {
    border-color: #EB2F51;
    background: none !important;

    .text {
      color: #EB2F51;
    }
  }

  .connect-failed {
    width: 100%;
    @extend %flex;

    .button {
      width: auto;
      height: 30px;
      padding: 0 12px;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .wallet-item.meta-mask {
    background: url('../image/metaMask.svg') 100% center no-repeat;
    background-origin: content-box;
  }

  .wallet-item.wallet-connect {
    background: url('../image/walletConnect.svg') 100% center no-repeat;
    background-origin: content-box;
  }
}

.content.success,
.content.failed {
  .form-content {
    height: 540px;
    width: 230px;
    text-align: center;
    @extend %flex-center;
    flex-direction: column;

    h5 {
      margin-top: 34px;
      margin-bottom: 12px;
      font-weight: bold;
      line-height: 29px;
    }

    p {
      margin-bottom: 120px;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.content.failed {
  width: 100%;

  .form-content {
    width: 300px;
  }

  .button-group {
    @extend %flex-center;
  }

  .button {
    width: 200px;
    margin: 0 8px;
  }
}

.content.claim {
  text-align: center;

  .form-content {
    @extend %flex-center;
    flex-direction: column;
    margin-top: 200px;

    h4 {
      margin-top: 12px;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 180px;
    }
  }
}

.tag-line {
  margin-top: -10px;
}

.social-area {
  width: 100%;
  padding-bottom: 48px;
  @extend %flex;
  align-items: flex-end;
  bottom: 30px;
  margin: 50px auto;
  max-width: 1320px;

  .social {
    left: 20px;
    @extend %flex-center;

    a {
      text-align: right;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .social-text {
    text-align: right;
    position: absolute;
    right: 0;
  }

}

@media only screen and (max-width: 480px) {


  .filter {
    display: none;
  }

  .desktop {
    display: inline;
  }

  .pool-status {
    margin-left: 0;
  }

  .nav-container {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    z-index: 9;
    top: 0;
    left: 0;

    .close {
      position: absolute;
      right: 22px;
      top: 22px;
    }

    .wallet-info {
      .wallet-info-item {
        height: 40px;
        border: 1px solid black;
        border-radius: 6px;
        padding-left: 20px;
        background-color: #F5F7FC;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .network {
        background-color: rgba(243, 132, 30, 0.05);
        color: rgb(243, 132, 30);
        font-weight: 500;
        margin-right: 0.5em;
        border-radius: 5px;
        padding: 5px 10px;
      }

      .copyArea {
        position: relative;
        border-left: 1px solid black;
        border-radius: 6px;
        height: 100%;
        background-color: white;
        padding: 0 20px;
        margin-left: 16px;
        display: flex;

        .copied {
          position: absolute;
          top: 26px;
          right: 10px;
          font-size: 12px;
          padding: 4px 10px;
          color: white;
          background-color: black;
          border-radius: 4px;
        }
      }

      .account {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          margin-left: 0.5em;
        }
      }
    }
  }


  .content.stake {
    .stake-title {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      font-size: 20px;
      line-height: 24px;
    }

    .stake-button-layout {
      position: absolute;
      right: 0;

      .stake-button {
        width: 100%;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 10px 12px;
        font-size: 16px;
        text-align: center;
      }

      .active {
        background: #000000;
        border: 1px solid #000000;
        color: #fff;
      }

      .stake-button:hover {
        background: #000000;
        border: 1px solid #000000;
        color: #fff;
      }
    }

    input {
      border: 1px solid rgba(0, 0, 0, 0.8);
      border-radius: 6px;
      width: 100%;
      height: 40px;
      padding-left: 14px;
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .fee {
      font-size: 24px;
      font-weight: bold;
      width: 58%;
      line-height: 29px;
      margin: auto;
      text-align: center;
    }

    .unstake {
      width: 54%;
    }

    .fee-tip {
      font-size: 16px;
      width: 72%;
      line-height: 26px;
      margin: auto;
      text-align: center;
      margin-top: 10px;
    }

    .balance {
      float: right;
    }

    .more {
      margin-top: 0;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .weight-grey {
      color: #000;
      background: #F8F8F9;
      border-radius: 6px;
      font-weight: normal;
      margin: auto;
      padding: 0 20px 10px;
      width: 100%;
      display: flex;
      flex-direction: column;

      span {
        margin-top: 10px;
      }
    }


    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }

    .button-layout {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

  }

  .balance-max {
    position: absolute;
    left: 220px;
    font-size: 14px;
    background-color: #EEEFF3;
    padding: 6px 20px;
    margin: auto;
    top: 5px;
    border-radius: 6px;
    cursor: pointer;
  }


  .social-area {
    display: none;
  }

  .mobile-pool-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    .more-info {
      display: block;
      width: 12px;
      height: 12px;
    }
  }


  .form-stake {
    height: 500px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
  }

  .stake-button {
    margin-bottom: 14px;
  }

  .form-title {
    padding: 0 16px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }

  .select-container {
    width: 120px;
    height: 40px;

    .only-icon {
      width: 40px;
      height: 40px;
    }
  }

  .select {
    width: 120px;
    height: 40px;
    font-size: 16px;
    color: black;
    text-align: center;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../image/down.png") no-repeat scroll right 12px center transparent;
    background-size: 14px auto;
    align-items: center;
    cursor: pointer;
    position: absolute;
    flex-direction: column;
    z-index: 2;
  }

  .select-default {
    width: 120px;
    height: 40px;
    line-height: 40px;
    z-index: -1;

    .options {
      display: flex;
      right: 0;
    }
  }

  .options {
    display: none;
    width: 80%;
    color: black;
    background: #fff;
    line-height: 30px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    align-items: center;
    height: fit-content;
    flex-direction: column;
    padding-bottom: 10px;
    z-index: 999;
    text-align: left;
  }


  .container {
    padding: 0 20px;
  }

  .desktop {}


  .stake-title {
    li {
      font-size: 16px;
      padding: 0 16px;
    }
  }

  .mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 234px;
    text-align: center;
    left: 0;
    display:none;
    img {
      margin-bottom: 34px;
    }

    p {
      width: 280px;
      font-size: 18px;
      line-height: 130%;
    }
  }

  .head {
    height: 58px;

    img {
      height: 26px;
    }

    .action {
      display: none;
    }

    .menu {
      display: block;
    }
  }

  .social {
    display: none;
  }

  .content {
    .return {
      top: 18px;
    }

    .form {
      margin-bottom: 30px;
    }

    .form-head {
      padding-left: 0;
      height: 55px;
      font-size: 14px;
      line-height: 17px;
    }

    .link {
      font-size: 14px;
      line-height: 17px;
    }

    .form-content {
      margin-top: 20px;
      padding: 0 16px;
    }

    .search {
      margin-bottom: 21px;

      input {
        padding-left: 14px;
      }
    }

    .pool-head {
      border-bottom: none;
      justify-content: flex-start;
      align-items: flex-end;
      height: 40px;
      padding-bottom: 0;

      li:nth-child(1) {
        display: flex;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin: 0 4px;
      }

      li:nth-child(2) {
        display: none;
      }

      li:nth-child(3) {
        width: 80px;
      }

      li:nth-child(4) {
        width: 140px;
      }

      li:nth-child(5) {
        width: 80px;
        overflow: hidden;
      }

      li:nth-child(6) {
        display: none;
      }

      li:nth-child(7) {
        display: none;
      }

      li:nth-child(8) {
        display: none;
      }
    }

    .button {
      padding: 0;
    }

    .pool-content {


      .pool-head,
      .pool-item {
        border-bottom: none;
        justify-content: flex-start;

        .mobile-pool-status {
          width: 6px;
          height: 6px;
          border-radius: 3px;
          display: block;
        }

        li:nth-child(1) {
          display: flex;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          margin: 0 8px;
        }

        li:nth-child(2) {
          display: none;
        }

        li:nth-child(3) {
          width: 80px;
        }

        li:nth-child(4) {
          width: 112px;
        }

        li:nth-child(5) {
          width: 80px;
          overflow: hidden;
        }

        li:nth-child(6) {
          display: none;
        }

        li:nth-child(7) {
          display: none;
        }

        li:nth-child(8) {
          display: none;
        }
      }

      li.status {}

      .status.Live {
        background-color: #36C98E;
        font-size: 0;
      }

      .status.Closed {
        background-color: #EB2F51;
        font-size: 0;
      }

      .status.Filled {
        background-color: rgba(11, 108, 185, 1);
        font-size: 0;
      }
    }

    .pool-item {
      height: 48px;
      font-size: 13px;
      line-height: 16px;
      justify-content: space-between;
      width: 100%;

      li.pool-id {
        display: none;
      }

      li.pool-auth {
        .tip {
          width: 80px;
          margin-right: 10px;
        }

        .tip span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      li.pool-contract {
        .tip {
          width: 160px;
          margin-right: 20px;
        }

        .tip span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      li.pool-pair {
        font-family: RobotoMono;

        em {
          font-weight: bold;
        }
      }


      .button {
        font-size: 0;
        width: 30px;
        height: 30px;
      }

      .button:after {
        font-size: 24px;
        content: '+';
        color: black;
        line-height: 24px;
      }

      .button:hover:after {
        color: white;
      }
    }
  }

  .content {
    .home {
      .form-head {
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;

        .select-tip {
          display: none;
        }
      }

      .search {}

      .form-content {
        width: 100%;
        padding: 0;

        .search {
          margin: 0 16px;
        }
      }

    }

  }

  .content.create-pool {
    .form-head {
      padding-left: 14px;
    }

    .field {
      margin-bottom: 24px;

      dt {
        font-size: 13px;
        line-height: 16px;
      }


      .max {
        position: absolute;
        z-index: 1;
      }
    }

    .field-row {
      li:first-child input {
        width: 120px;
      }

      li:last-child input {
        width: 70px;
      }
    }
  }

  .content.join {
    .join-title {
      padding-left: 14px;
      font-size: 14px;
      line-height: 17px;
    }

    .define-row {
      flex-direction: column;
      align-items: flex-start;

      .ratio {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .content.claim,
  .content.success {
    .button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .content.failed {
    .button-group {
      flex-direction: column;
      width: 100%;

      .button {
        margin-bottom: 14px;
      }
    }
  }
}

.loading {

  margin-top: 20px;
  border: 4px solid #f3f3f3;

  border-radius: 50%;

  border-top: 4px solid #1F65ED;

  width: 70px;

  height: 70px;

  -webkit-animation: spin 2s linear infinite;

  animation: spin 2s linear infinite;

}

@-webkit-keyframes spin {

  0% {

    -webkit-transform: rotate(0deg);

  }

  100% {

    -webkit-transform: rotate(360deg);

  }

}

@keyframes spin {

  0% {

    transform: rotate(0deg);

  }

  100% {
    transform: rotate(360deg);
  }
}




.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.modal-button {
  width: 200px;
  height: 40px;
  margin: auto;
  margin-top: 40px;
}

.modal-close {
  cursor: pointer;
}


.un-expanded {
  display: none;
}

.expanded {
  display: flex;
}

.mobile-pool-extra-item {
  flex: 1;
  padding: 0 20px;
  font-size: 12px;
  padding-bottom: 12px;

  p:nth-child(1) {
    line-height: 14px;
    color: #000000;
    opacity: 0.44;
  }

  p:nth-child(2) {
    color: #000;

  }
}

 .GovernanceVault{
     width: 600px;
     box-sizing: border-box;
     margin: 40px auto;
     .info_box{
       width: 600px;
       height: 160px;
       border: 1px solid #000;
       border-radius: 10px;
       padding-left: 35px;
       div{
         margin-top: 25px;
         h1{
           font-size: 14px;
           line-height: 17px;
           font-weight: bold;
         }
         p{
           font-size: 16px;
           line-height: 19px;
           margin-top: 6px;
         }
       }
     }

     .Signers{
       width: 600px;
       border: 1px solid #000;
       border-radius: 10px;
       margin-top: 24px;
       box-sizing: border-box;

       .title{
         border-bottom: 1px solid #020202;
         padding: 20px 35px;

         h1{
           font-size: 20px;
           line-height: 24px;
         }
       }

       ul{
         padding: 0 35px;
         li{
           display: flex;
           align-items: center;
           padding: 15px 0;
           border-bottom: 1px solid #020202;
           justify-content: space-between;
           &:nth-last-child(1){
             border: none;
           }

           .logo{
             width: 32px;
             height: 32px;
             background: url(../image/logo-1.svg) no-repeat;
             background-size: contain;
             background-position: 0 0;
           }

           p{
             font-size: 16px;
             line-height: 23px;
           }
           button{
             width: 70px;
             height: 30px;
             box-sizing: border-box;
             border: 1px solid #020202;
             font-size: 14px;
             border-radius: 10px;
           }
         }
       }
     }
   }


.vote-result{
  .count{
    width: 100px;
    height: 30px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
  }
}

.gov-balance{
  width: 180px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #000;
  margin-left: 1100px;
  margin-top: 40px;
  flex-direction: column;
  background-color: #F8F8F9;;
}

.hide{
  display: none!important;
}

// Xuminhui
.GovernanceNav {
  .navContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: rgba(0, 0, 0, .6);
    font-size: 16px;

    li {
      margin: 0 20px;
      cursor: pointer;
    }

    li.active {
      color: #000;
      border-bottom: 1px solid #000;
    }
  }

  .GovernanceList {
    margin-top: 40px;

    li {
        margin: 0 auto;
        width: 650px;
        height: 80px;
        border: 1px solid #000;
        border-radius: 6px;
        margin-bottom: 16px;
      .GovernanceListItem {
        display: flex;
        height: 80px;
        justify-content: space-between;
        padding: 0 2em;
        cursor: pointer;

        &>div {
          padding: 10px 0;

          h1 {
            line-height: 60px;
            font-size: 20px;
            text-indent: 1.5em;
          }

          .title {
            font-size: 12px;
            color: rgba(0, 0, 0, .44);
            margin-bottom: 10px;
          }

          .data {
            font-size: 16px;
            line-height: 28px;
          }

          .status {
            width: 68px;
            height: 28px;
          }

          .live {
            color: #36C98E;
            background-color: rgb(235, 250, 244);
          }

          .passed {
            color: #077BE5;
            background-color: rgb(230, 242, 252);
          }

          .fail {
            color: #EB2F51;
            background-color: rgb(253, 234, 238);
          }
        }

        .iconBox {
          position: absolute;
          width: 16px;
          height: 16px;
          background: url(../image/xiangxia.svg) no-repeat;
          background-size: 16px;
          cursor: pointer;
          right: 1.5em;
          top: 32px;
        }
      }
      .itemOpen{
        display: none;
      }
    }

    li.open{
      height: auto;
      .itemOpen{
        display: flex;
        justify-content: center;
        button{
          width: 294px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #000;
          font-size: 16px;
          border-radius: 6px;
          margin: 24px 0;
          &:nth-child(2){
            margin-left: 1em;
            color: #fff;
            background-color: #000;
          }
        }
      }
    }
  }
}

.createGov{
  width: 732px !important;
  max-width:730px !important;
  min-height: 480px!important;
  .form-head{
    div{
      position: absolute;
      right: 1em;
      display: flex;
      .button{
        font-size: 16px;
        &:nth-child(2){
          margin-left: 1em;
        }
      }
    }
  }

  form{
    width:665px !important;
    max-width:665px !important;
    dl.field{
      margin-bottom: 24px !important;
    }

    dl.btnBox{
      display: flex;

      button{
        font-size: 16px;
        width: 326px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #000;
        border-radius: 6px;

        &:nth-child(2){
          background-color: #000;
          color: #fff;
          margin-left: 16px;
        }
      }
    }
  }
}

.field_area{
  textarea{
    height: 80px;
    border-radius: 6px;
    padding: 10px 1em;
    &::placeholder{
      color: rgba(0,0,0,.5);
    }
  }
}
